import React, {Fragment} from 'react'
import './RichText.scss'

const RichText = (props) => {
    const {seoText} = props

    return (
        <div className="footer__container">
            <div dangerouslySetInnerHTML={{ __html: seoText }} />
        </div>
    )
}

export default RichText