import axios from 'axios'
const { siteMetadata } = require('../../../../gatsby-config');
const quickgenAPI = siteMetadata.quickgenAPI;
const imageBaseURL = siteMetadata.imageBaseURL;

//Return siblings of filter selected onClick
export const getSiblings = (elem) => {
    let siblings = []
    let sibling = elem.parentNode.firstChild

    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem) {
			siblings.push(sibling);
		}
        sibling = sibling.nextSibling
    }

    return siblings
}



//Sets inital values for page load
export const getValues = (id, setSpecials, setFilters, setAllSpecials, setPlaceholderValue, setContentType, setModel) => {
    axios
    .get(quickgenAPI + '/quickgen/getMultipleSpecialsByType/?dealer_id=' + id + '&type=Finance,Lease,managerFinance,managerLease,cashPrice,offMSRP,percentOff')
    .then(res => {
        let specialsData = res.data.specialsData
        let allSpecials = Object.values(specialsData).flat()
        let modelsArr = allSpecials.map(special => special.model)
        let filteredModels = [...new Set(modelsArr)]
        let filters = Object.keys(specialsData)
        filters.push(...filteredModels)
        console.log(filters)
        const convertQS = window.location.search;
        let params = new URLSearchParams(convertQS);
        let type = params.get("type");
        let model = params.get("model")
        
        //Remove spaces from model
        model !== null && model.replace("20%", ' ')

        //If model param is missing switch display all specials
        if (type === 'Model' && model === null) {
            type = 'All'
            model = 'none'
        }

        //If model param is missing and type is not Model, set model to none to show value was set to complete loading
        if (model === null) {
            model = 'none'
        }

        //If type param is not All or Model or Included in the filters available, display all specials
        if (type !== 'All' && type !== 'Model' && !filters.includes(type)) {
            type = 'All'
        }

        //If the filters include placeholder, then no specials were found and we display Offer Card with placeholder info
        if (filters.includes('placeholder')) {
            setPlaceholderValue(true)
        }

        //Set initial values
        setAllSpecials(allSpecials)
        setFilters(filters)
        setSpecials(specialsData)
        setModel(model)
        setContentType(type)
    })
    .catch(err => {
        console.log(err)
    })
}


//Format date from iso to mm/dd/yyyy
export const formatDate = (date) => {
    let newDate = date.split('T')[0].split('-').reverse().splice(0,2).reverse().concat(date.split('T')[0].split('-').splice(0,1))
    return newDate.join('/')
}



//replaces current prod url with dev url to view without FTP whitelisting
export const formatDevURL = (url) => {
    return url.replace('https://images.mxssolutions.com', imageBaseURL)
}



//Formats Trim string for offer cards
export const setTrim = (trim) => {
    if (trim === '') {
        return trim
    }

    const newTrim = trim.split(' ')
    const condition = "Applies To All Trims"

    if (trim === condition || newTrim.includes('Excludes')) {
        return trim
    }

    return 'Applies to ' + trim
}





//OnClick from 'Find A Dealer' button on offer cards, renders dealer finder
export const handleFindDealerClick = (setContentType) => {
    let elem = document.getElementById('Dealer')
    let siblings = getSiblings(elem)
    siblings.map(sibling => {
        if (sibling.classList.contains('selected')) {
            sibling.classList.remove('selected')
            elem.classList.add('selected')
        }
    })
    window.scrollTo({ top: 0, behavior: "instant" })
    return setContentType('Dealer')
}


